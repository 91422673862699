/* portfolio section */
#portfolio {
    padding: 50px 0;
}

.work-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;


}

.work {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}


.work img {
    width: 95%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    display: block;
    transition: transform 0.5s;
}



.layer {
    width: 95%;
    height: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.6), #ff004f);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    font-size: 14px;
    transition: heigth 0.5s;
}

.project-headings {
    font-weight: 650;
    margin-bottom: 20px;
}

.layer a {
    margin-top: 20px;
    color: #ff004f;
    font-size: 18px;
    line-height: 53px;
    background: white;
    width: 60px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    text-decoration: none;
}



.work:hover img {
    transform: scale(1);
}

.work:hover .layer {
    height: 100%;
}


.btn {
    display: block;
    margin: 50px auto;
    width: fit-content;
    border: 1px solid #ff004f;
    padding: 14px 50px;
    border-radius: 6px;
    text-decoration: none;
    color: white;
    transition: background 0.5s;

}

.btn:hover {
    background: #ff004f;
}