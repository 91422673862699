
/* Services section */
#services {
    padding: 30px 0;

}

.services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;
}

.services-list div {
    background: #262626;
    padding: 40px;
    font-size: 13px;
    font-weight: 300;
    border-radius: 10px;
    transition: background 0.5s, transform 0.5s;
}

.services-list div i {
    font-size: 50px;
    margin-bottom: 30px;
}

.services-list div h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;

}

.services-list div a {
    font-size: 12px;
    text-decoration: none;
    color: #fff;
    margin-top: 20px;
    display: inline;
}

.services-list div:hover {
    background: #ff004f;
    transform: translateY(-10px);
}