
* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}


html {
  scroll-behavior: smooth;
}

body {
  background-color: rgb(33, 29, 29);
  color: white;
}

