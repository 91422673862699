.about {
    padding: 80px 0;
    color: antiquewhite;
}

.row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.about-col1 {
    flex-basis: 35%;

}

.about-col1 img {
    width: 100%;
    border-radius: 15px;
}

.about-col2 {
    flex-basis: 60%;
    ;
}

.subTitle {
    font-size: 60px;
    font-weight: 600;
    color: #fff;
}

.tab-titles {
    display: flex;
    margin: 20px 0 40px;
}

.tab-links {
    margin-right: 50px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.tab-links::after {
    content: '';
    width: 0;
    height: 3px;
    background: #ff004f;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: 0.5s;
}

.tab-links.active-link::after {
    width: 50%
}

.tab-contents ul li {
    list-style: none;
    margin: 10px 0;

}

.tab-contents ul li span {
    color: #b54769;
    font-size: 20px;

}

.tab-contents ul li span i {
    color: #b54769;
    font-size: 18px;

}

.tab-contents ul li i {
    /* color: #b54769;
    font-size: 20px; */
    line-height: 28px;

}



.tab-contents {
    display: none;

}

.tab-contents.active-tab {
    display: block;
}
