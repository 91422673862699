.container {
    padding: 10px 10%;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.logo {
    width: 140px;

}

.nav ul li {
    display: inline-block;
    list-style: none;
    margin: 10px 20px;
    font-weight: bold;
}

/* nav {
    position: fixed;
} */

.nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 30px;
    position: relative;
}

.nav ul li a::after {
    content: '';
    width: 0;
    height: 3px;
    background: #ff004f;
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: 0.5s;
}

.nav ul li a:hover::after {
    width: 100%;

}


/* Details section */
.header-text {
    /* margin-top: 20%; */
    margin: 10% 10px 10% 10px;
    font-size: 30px;
}

.header-text h1 {
    font-size: 60px;
    margin-top: 20px;
    line-height: 80px;
}

.header-text h1 span {
    color: #ff004f;
}