

/* Contact Section */

.contact-left {
    flex-basis: 35%;
}

.contact-left p {
    margin-top: 30px;

}

.contact-left p i {
    color: #ff004f;
    margin-right: 15px;
    font-size: 25px;
}

.social-icons {
    margin-top: 30px;
}

.social-icons a {
    text-decoration: none;
    font-size: 30px;
    margin-right: 15px;
    color: #ababab;
    display: inline-block;
    transition: transform 0.5s;

}

.contact-left a:hover {
    color: #ff004f;
    transform: translateY(-5px);
}



.btn.btn2 {
    display: inline-block;
    background-color: #ff004f;
}


.contact-right {
    flex-basis: 60%;
}

.contact-right form {
    width: 100%;
}

form input,
form textarea {
    border: 0px;
    width: 100%;
    outline: none;
    background: #262626;
    padding: 15px;
    margin: 15px 0;
    font-size: 18px;
    border-radius: 6px;
    color: #fff;
}

form bt2 {
    padding: 14px 16px;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}